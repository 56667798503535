import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Toolbar from './Toolbar';
import GlobeIcon from '../images/globe.png';
import KibanaIcon from '../images/kibana.png';
import UserMenuDropdown from './UserMenuDropdown';
import BurgerMenuDropdown from './BurgerMenuDropdown';
import './LandingBody.css';
import Accordion from './Accordion';

const LandingBody = (props) => {
    const [burgerHover, setBurgerHover] = useState(false);
    const [userHover, setUserHover] = useState(false);
    const [displayBurgerMenu, setDisplayBurgerMenu] = useState(false);
    const [displayUserMenu, setDisplayUserMenu] = useState(false);
    const navigate = useNavigate();
    function launchBtn(title) {
        switch(title) {
            case 'SensoriumGIS':
                navigate('/sensoriumgis');;
                break;
            case 'Kibana': 
                navigate('/kibana');
                break;
            default:
                console.log('System not found')
        }
    }
    return (
        <div>
            <div className='main-container'>
                <Toolbar 
                    burgerHover={burgerHover}
                    setBurgerHover={setBurgerHover}
                    displayBurgerMenu={displayBurgerMenu}
                    setDisplayBurgerMenu={setDisplayBurgerMenu}
                    userHover={userHover}
                    setUserHover={setUserHover}
                    displayUserMenu={displayUserMenu}
                    setDisplayUserMenu={setDisplayUserMenu}
                    user={props.user}
                />
                { displayBurgerMenu ?
                    <BurgerMenuDropdown 
                        setDisplayBurgerMenu={setDisplayBurgerMenu}
                        setBurgerHover={setBurgerHover}
                    />
                    : <></>
                }
            
                { displayUserMenu ?
                    <UserMenuDropdown
                        setDisplayUserMenu={setDisplayUserMenu}
                        setUserHover={setUserHover}
                        signOut={props.signOut}
                    />
                    : <></>
                }

                <div className='accordion-container'>
                    <Accordion
                        title='SensoriumGIS'
                        image={GlobeIcon}
                        imageHeight='40px'
                        imageWidth='40px'
                        imageAlt='Globe Icon'
                        content='Control Room Graphical Information System.'
                        btnTitle='Launch'
                        btnLink={launchBtn}
                    />
                    <Accordion
                        title='Kibana'
                        image={KibanaIcon}
                        imageHeight='40px'
                        imageWidth='40px'
                        imageAlt='Kibana Icon'
                        content='Visualise the Elasticsearch data in the control room Elastic Database.'
                        btnTitle='Launch'
                        btnLink={launchBtn}
                    />
                </div>
            </div>
        </div>
    );
};
export default LandingBody;
