import './App.css';
import React from 'react'
import { Amplify, Auth } from 'aws-amplify';
import { useNavigate } from "react-router-dom";

// Set Environment
var environment = {};
const subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : 'dev';
if (subdomain === 'dev') {
  environment['userpoolId'] = process.env.REACT_APP_USERPOOL_ID_DEV;
  environment['webClientId'] = process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID_DEV;
  environment['region'] = process.env.REACT_APP_AWS_REGION_DEV;
  environment['redirectSignin'] = process.env.REACT_APP_AWS_REDIRECT_SIGNIN_DEV;
  environment['redirectSignout'] = process.env.REACT_APP_AWS_REDIRECT_SIGNOUT_DEV;
  environment['domain'] = process.env.REACT_APP_COGNITO_DOMAIN_DEV;
} else {
  environment['userpoolId'] = process.env.REACT_APP_USERPOOL_ID_PROD;
  environment['webClientId'] = process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID_PROD;
  environment['region'] = process.env.REACT_APP_AWS_REGION_PROD;
  environment['redirectSignin'] = process.env.REACT_APP_AWS_REDIRECT_SIGNIN_PROD;
  environment['redirectSignout'] = process.env.REACT_APP_AWS_REDIRECT_SIGNOUT_PROD;
  environment['domain'] = process.env.REACT_APP_COGNITO_DOMAIN_PROD;
}

Amplify.configure({
  Auth: {
    region: environment.region,
    userPoolId: environment.userpoolId,
    userPoolWebClientId: environment.webClientId,
    cookieStorage: {
      domain: 'therokesensorium.com',
      path: '/',
      secure: true
    },
    oauth: {
      domain: `${environment.domain}.auth.${environment.region}.amazoncognito.com`,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: environment.redirectSignin,
      redirectSignOut: environment.redirectSignout,
      clientId: environment.webClientId,
      responseType: 'code'
    }
  }
});

export default function App(props) {
  const navigate = useNavigate();
  const logon = () => {
    navigate('/logon');
  }
  return (    
    <div className='app'>
      <div className='content-container'>
        <div className='title-container'>
          <h1>Welcome to Sensorium</h1>
        </div>
        <div className='logon-btn-container'>
          <button className='logon-btn' onClick={logon}>Logon</button>
        </div>
      </div>
    </div>
  )
}

