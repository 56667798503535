import React, { Component }  from 'react';
import {
  BrowserRouter as Router
} from 'react-router-dom';

export default function ExternalRoutes(props) {
  const subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : 'dev';

  // Dev Links
  if (subdomain == 'dev') {
    switch(props.redirect) {
      case 'logon':
        window.location = `https://${process.env.REACT_APP_COGNITO_DOMAIN_DEV}.auth.${process.env.REACT_APP_AWS_REGION_DEV}.amazoncognito.com/login?client_id=${process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID_DEV}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_SIGNIN_DEV}`
        break;
      case 'sensoriumgis':
        window.location = "https://dev.gis.therokesensorium.com"
        break;
      case 'kibana':
        window.location = "https://dev.es.therokesensorium.com"
        break;
      default:
        console.log('Error - no link');
    }
  // Prod Links
  } else {
    switch(props.redirect) {
      case 'logon':
        window.location = `https://${process.env.REACT_APP_COGNITO_DOMAIN_PROD}.auth.${process.env.REACT_APP_AWS_REGION_PROD}.amazoncognito.com/login?client_id=${process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID_PROD}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_SIGNIN_PROD}`
        break;
      case 'sensoriumgis':
        window.location = "https://gis.therokesensorium.com"
        break;
      case 'kibana':
        window.location = "https://es.therokesensorium.com"
        break;
      default:
        console.log('Error - no link');
    }
  }
  return null;
}
