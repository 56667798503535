import React, { Component }  from 'react';
import './Toolbar.css';
import userIcon from '../images/user-icon.png';
import userIconGold from '../images/user-icon-gold.png';
import burgerIcon from '../images/burger-icon.png';
import burgerIconGold from '../images/burger-icon-gold.png';

const Toolbar = (props) => {
    return (
      <div className='toolbar-container'>
        <div className='burger-title-container'>
          {
            props.burgerHover ?
              <img
                className='burger-icon'
                src={burgerIconGold} alt='burger icon'
                onMouseEnter={()=> {
                  props.setBurgerHover(true);
                  props.setDisplayBurgerMenu(true);
                }}>
              </img>
            :
              <img
                className='burger-icon'
                src={burgerIcon} alt='burger icon'
                onMouseEnter={()=> {
                  props.setBurgerHover(true);
                  props.setDisplayBurgerMenu(true)
                }}>
              </img>
          }
        </div>
        <div className='text-title-container'> Sensorium </div>
        <div className='user-container'> Welcome, {props.user.attributes.email} </div>
        <div className='menu-container'>
          {
            props.userHover ?
              <img 
                className='user-icon'
                src={userIconGold} alt='user icon'
                onMouseEnter={()=> {
                  props.setUserHover(true);
                  props.setDisplayUserMenu(true)
                }}
              />
            :
              <img 
                className='user-icon'
                src={userIcon} alt='user icon'
                onMouseEnter={()=> {
                  props.setUserHover(true);
                  props.setDisplayUserMenu(true)
                }}
              />
          }
        </div>
        
      </div>
    );
};
export default Toolbar;
