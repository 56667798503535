import React, { useContext, Component } from 'react';
import './UserMenuDropdown.css';
import { Auth } from 'aws-amplify'
import { useNavigate } from "react-router-dom";

const UserMenuDropdown = (props) => {
  const navigate = useNavigate();
  const logout = () => {
    Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err));
    navigate('/');
  }
  return (
      <div 
        className="user-menu-dropdown-container"
        onMouseLeave={()=> { 
          props.setDisplayUserMenu(false);
          props.setUserHover(false);
        }}
      > 
        <ul className='user-menu-item-container'>
          <li className='user-menu-item' onClick={logout}>Logout</li>
        </ul>
      </div>
  );
};
export default UserMenuDropdown;