import React, { Component }  from 'react';
import { useNavigate } from "react-router-dom";
import './BurgerMenuDropdown.css';

const BurgerMenuDropdown = (props) => {
  const navigate = useNavigate();
  const menuClick = (id) => {
    switch(id) {
      case 'sensoriumgis':
        navigate('/sensoriumgis');
        break;
      case 'kibana':
        navigate('/kibana');
        break;
      default:
    }
  }
  return (
    <div 
        className='burger-menu-dropdown-container'
          onMouseLeave={()=> {
          props.setDisplayBurgerMenu(false);
          props.setBurgerHover(false);
        }}
      >
        <ul className='burger-menu-item-container'>
          <li id='sensoriumgis' className='burger-menu-item' onClick={e => menuClick(e.target.id)}>SensoriumGIS</li>
          <li id='kibana' className='burger-menu-item' onClick={e => menuClick(e.target.id)}>Kibana</li>
        </ul>
      </div>
  );
};
export default BurgerMenuDropdown;