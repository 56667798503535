import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import LandingBody from './LandingBody';
import './Home.css';
import { Auth, Hub } from 'aws-amplify'

function Home() {
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true);

  async function getCurrentUser() {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      setUser(authUser);
      setIsLoading(false);  
    } catch (err) {
      console.log('Error: ', err);
      setUser(null);
      setIsLoading(false);  
    }
  }

  useEffect(() => {
    getCurrentUser();
  }, []);

  if (!user && !isLoading) {
    return <Navigate to="/" replace={true} />
  }

  return (
    <div className='home'>
      {
        user && !isLoading ?
          <LandingBody user={user}/>
        :
          <></>
      }
    </div>
  );
}

export default Home;
