import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "../src/components/Home";
import ExternalRoutes from "../src/components/ExternalRoutes";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Routes>
        <Route exact path="/" element={<App />}></Route>
        <Route exact path="/logon" element={<ExternalRoutes redirect='logon' />}/>
        <Route exact path="/dashboard" element={<Home />}></Route>
        <Route exact path="/sensoriumgis" element={<ExternalRoutes redirect='sensoriumgis' />}/>
        <Route exact path="/kibana" element={<ExternalRoutes redirect='kibana' />}/>
      </Routes>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
